import { graphql } from 'gatsby';
import { createUseStyles } from 'react-jss';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import Carousel from '../components/Carousel';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import BookNowButton from '../components/BookNowButton';

const useStyles = createUseStyles(() => ({
  amenities: {
    columns: 2,
  },
  bookNowContainer: {
    textAlign: 'right',
  },
  headerAmenities: {
    textAlign: 'center',
  },
  headerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  information: {
    marginTop: '2rem',
  },
}));

const Room = (props) => {
  const classes = useStyles(props);
  const {
    data,
  } = props;

  const {
    roomsJson,
  } = data;

  const imagesSortted = roomsJson.images.sort((a, b) => {
    const aIndex = roomsJson.imageNames.indexOf(a.fluid.originalName);
    const bIndex = roomsJson.imageNames.indexOf(b.fluid.originalName);
    return aIndex - bIndex;
  });

  const imageComponents = imagesSortted.map((node, i) => (
    <Img alt={roomsJson.imageLabels[i]} fluid={node.fluid} />
  ));

  return (
    <Layout>
      <SEO title={roomsJson.title} />
      <h1 className={classes.headerTitle}>
        {roomsJson.title}
        <BookNowButton propertyType={roomsJson.propertyType} />
      </h1>
      <Carousel
        images={imageComponents}
      />
      <div className={classes.information}>
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: roomsJson.description }} />
        <h2 className={classes.headerAmenities}>
          Amenities
        </h2>
        <ul className={classes.amenities}>
          {roomsJson.amenities.map((amenity) => (
            <li key={amenity}>
              {amenity}
            </li>
          ))}
        </ul>
        <div className={classes.bookNowContainer}>
          <BookNowButton propertyType={roomsJson.propertyType} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    roomsJson(fields: { slug: { eq: $slug } }) {
      amenities
      description
      imageNames
      imageLabels
      images {
        fluid {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
      propertyType
      title
    }
  }
`;

Room.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default Room;
